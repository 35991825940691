<template>
  <Page color="grey" title="Einstellungen">
    <v-row>
      <v-col>
        <v-card max-width="500" class="mx-auto mb-4"
          ><v-system-bar>Namen von Personen</v-system-bar>
          <v-card-text>
            Wie sollen Namen von Peronen dargestellt werden?<br />
            Diese Einstellung beeinflusst die Reihenfolge bei sortierten Listen
            von Personen.
          </v-card-text>

          <v-list>
            <v-divider />
            <v-list-item-group v-model="peopleSort">
              <v-list-item value="firstName">
                <template v-slot:default="{ active }">
                  <v-list-item-content>
                    <v-list-item-title
                      >zuerst Vorname, dann Nachname
                    </v-list-item-title>
                    <v-list-item-subtitle
                      >Listen sind nach Vornamen sortiert
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-icon v-if="active" color="primary"
                      >mdi-radiobox-marked</v-icon
                    >
                    <v-icon v-else color="primary">mdi-radiobox-blank</v-icon>
                  </v-list-item-action>
                </template>
              </v-list-item>

              <v-list-item value="lastName">
                <template v-slot:default="{ active }">
                  <v-list-item-content>
                    <v-list-item-title
                      >zuerst Nachname, dann Vorname
                    </v-list-item-title>
                    <v-list-item-subtitle
                      >Listen sind nach Nachnamen sortiert
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-icon v-if="active" color="primary"
                      >mdi-radiobox-marked</v-icon
                    >
                    <v-icon v-else color="primary">mdi-radiobox-blank</v-icon>
                  </v-list-item-action>
                </template>
              </v-list-item>
            </v-list-item-group>
            <v-divider />
            <v-subheader>Beispiel</v-subheader>
            <PersonItem :value="$_profilePerson" :key="count" />
          </v-list>
        </v-card>
      </v-col>

      <v-col>
        <v-card max-width="500" class="mx-auto mb-4"
          ><v-system-bar>E-Mail-Client</v-system-bar>
          <v-card-text>
            Was soll bei einem Klick auf eine E-Mail geschehen? Soll diese in
            der Standard-E-Mail-App geöffnet werden oder auf unserer
            Outlook-Web-App?
          </v-card-text>

          <v-list>
            <v-divider />
            <v-list-item-group v-model="emailClient">
              <v-list-item value="mailto">
                <template v-slot:default="{ active }">
                  <v-list-item-content>
                    <v-list-item-title>Standard-App </v-list-item-title>
                    <v-list-item-subtitle
                      >auf dem Gerät registrierte E-Mail-App verwenden
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-icon v-if="active" color="primary"
                      >mdi-radiobox-marked</v-icon
                    >
                    <v-icon v-else color="primary">mdi-radiobox-blank</v-icon>
                  </v-list-item-action>
                </template>
              </v-list-item>

              <v-list-item value="outlook">
                <template v-slot:default="{ active }">
                  <v-list-item-content>
                    <v-list-item-title>Outlook Web-App </v-list-item-title>
                    <v-list-item-subtitle
                      >im Browser in der Gymer-Cloud öffnen
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-icon v-if="active" color="primary"
                      >mdi-radiobox-marked</v-icon
                    >
                    <v-icon v-else color="primary">mdi-radiobox-blank</v-icon>
                  </v-list-item-action>
                </template>
              </v-list-item>
            </v-list-item-group>
            <v-divider />
            <v-subheader>Beispiel</v-subheader>
            <v-card-text>
              <EmailChip :value="$_profilePerson.emailSchool" :key="count" />
            </v-card-text>
          </v-list>
        </v-card>
      </v-col>
      <v-col>
        <v-card max-width="500" class="mx-auto mb-4"
          ><v-system-bar>Icons</v-system-bar>
          <v-card-text>
            Sollen bei den Absenzen und den Fächern die Symbole angezeigt werden
            oder nicht?<br />(an einigen wenigen Stellen können Symbole nicht
            ausgeblendet werden)
          </v-card-text>

          <v-list>
            <v-divider />
            <v-list-item-group v-model="hideIcons">
              <v-list-item :value="false">
                <template v-slot:default="{ active }">
                  <v-list-item-content>
                    <v-list-item-title>Icons anzeigen </v-list-item-title>
                    <v-list-item-subtitle
                      >Symbole werden überall angezeigt
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-icon v-if="active" color="primary"
                      >mdi-radiobox-marked</v-icon
                    >
                    <v-icon v-else color="primary">mdi-radiobox-blank</v-icon>
                  </v-list-item-action>
                </template>
              </v-list-item>

              <v-list-item :value="true">
                <template v-slot:default="{ active }">
                  <v-list-item-content>
                    <v-list-item-title>Icons verbergen</v-list-item-title>
                    <v-list-item-subtitle
                      >Unterrichts- und Absenzen-Symbole werden nicht angezeigt
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-icon v-if="active" color="primary"
                      >mdi-radiobox-marked</v-icon
                    >
                    <v-icon v-else color="primary">mdi-radiobox-blank</v-icon>
                  </v-list-item-action>
                </template>
              </v-list-item>
            </v-list-item-group>
            <v-divider />
            <v-subheader>Beispiel</v-subheader>
            <v-card-text>
              <v-chip outlined color="primary"
                ><v-icon left v-if="!hideIcons">mdi-account</v-icon
                ><PersonName :value="$_profilePerson"></PersonName
              ></v-chip>
            </v-card-text>
          </v-list>
        </v-card>
      </v-col>

      <v-col>
        <v-card max-width="500" class="mx-auto mb-4" :loading="loading"
          ><v-system-bar>ICal-Abos</v-system-bar>
          <v-card-text>
            Du kannst hier das «Geheimnis» für dein ICal-Abo-Zugriff löschen,
            neu setzen oder auslesen.
          </v-card-text>

          <v-list>
            <v-list-item>
              <v-text-field
                label="secret"
                readonly
                :value="icalSecret"
              ></v-text-field>
            </v-list-item>
          </v-list>
          <v-divider></v-divider>

          <v-card-text>
            Die folgenden Kalender kannst du abonnieren. Kopiere dazu den Link
            (inkl. secret) und füge ihn in deiner Kalender-App als abonnierter
            Kalender hinzu.
          </v-card-text>
          <v-alert tile text type="info" v-if="!icalSecret">
            Erstelle zuerst ein «Geheimnis»
          </v-alert>

          <v-list v-else>
            <v-list-item>
              <v-text-field
                label="meine Proben"
                readonly
                :value="getIcalRoute('personalexams')"
              ></v-text-field>
            </v-list-item>
          </v-list>

          <v-divider></v-divider>
          <v-card-actions
            ><v-btn
              text
              color="danger"
              @click="removeIcalSecret"
              :disabled="!icalSecret"
              >löschen</v-btn
            ><v-spacer></v-spacer
            ><v-btn
              text
              color="primary"
              @click="addIcalSecret"
              :disabled="!!icalSecret"
              >generieren</v-btn
            ></v-card-actions
          >
        </v-card>
      </v-col>
    </v-row>
  </Page>
</template>
<script>
import { defineComponent } from "vue";
import EmailChip from "common/components/EmailChip.vue";
import PersonItem from "common/components/PersonItem.vue";
import PersonName from "common/components/PersonName.vue";
import { getHideIcons, setHideIcons } from "common/utils/icons.js";
import { getPeopleSort, setPeopleSort } from "common/utils/people.js";
import { getEmailClient, setEmailClient } from "common/utils/helper.js";

export default defineComponent({
  components: { EmailChip, PersonItem, PersonName },
  name: "Settings",
  data() {
    return {
      email: "",
      emailClient: "",
      peopleSort: "",
      hideIcons: false,
      count: 0,
      icalSecret: "",
      loading: false,
    };
  },
  watch: {
    emailClient() {
      setEmailClient(this.emailClient);
      this.count = this.count + 1;
    },
    peopleSort() {
      setPeopleSort(this.peopleSort);
      this.count = this.count + 1;
    },
    hideIcons() {
      setHideIcons(this.hideIcons);
      this.count = this.count + 1;
    },
  },
  methods: {
    getIcalRoute(resource) {
      return `https://api.gymkirchenfeld.ch/api/ical/${resource}/${this.$_profilePerson.id}/?secret=${this.icalSecret}`;
    },
    async getIcalSecret() {
      this.loading = true;
      const data = await this.apiGet({
        resource: "account/icalsecret",
        id: this.$_profilePerson.id,
      });
      this.icalSecret = data.icalSecret;
      this.loading = false;
    },
    async removeIcalSecret() {
      if (
        await this.$root.confirm({
          message: `Das Geheimnis wird gelöscht. Bist du sicher? Du kannst dann ein neues generieren.`,
          color: "danger",
          icon: "mdi-lock",
        })
      ) {
        this.loading = true;
        await this.apiPatch({
          resource: "account/icalsecret",
          id: this.$_profilePerson.id,
          key: "icalSecret",
          value: "delete",
        });
        await this.getIcalSecret();
        this.loading = false;
      }
    },
    async addIcalSecret() {
      if (
        await this.$root.confirm({
          message: `Ein Geheminis wird generiert und kann dann für den ICal-Zugriff verwendet werden.`,
          color: "success",
          icon: "mdi-lock",
        })
      ) {
        this.loading = true;
        await this.apiPatch({
          resource: "account/icalsecret",
          id: this.$_profilePerson.id,
          key: "icalSecret",
          value: "create",
        });
        await this.getIcalSecret();
        this.loading = false;
      }
    },
  },
  async created() {
    this.emailClient = getEmailClient();
    this.peopleSort = getPeopleSort();
    this.hideIcons = getHideIcons();
    this.getIcalSecret();
  },
});
</script>
